import { Col, DatePicker, Form, Input, Row, Spin, Upload } from "antd"
import React, { useState } from "react"
import { NewFormStyle } from "../../styled"
import { UserOutlined } from "@ant-design/icons"
import { normFile } from "src/lib/utils"
import Button from "src/components/MyButton/Button"
import SvgIcon from "src/components/SvgIcon"
import FileService from "src/services/FileService"
import PractiseService from "src/services/PractiseService"
import "suneditor/dist/css/suneditor.min.css" // Import Sun Editor's CSS File
import Notice from "src/components/Notice"
import { useEffect } from "react"
import dayjs from "dayjs"
import STORAGE, { getStorage } from "src/lib/storage"
import RegionSelect from "src/components/RegionSelect"
const { RangePicker } = DatePicker
const { TextArea } = Input

const FormInfo = ({ onCancel, edit, practise_id, getData, data }) => {
  const [avatarUpload, setAvatarUpload] = useState("")
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const userInfo = getStorage(STORAGE.USER_INFO)

  // updatePractiseByID
  const createForm = async () => {
    try {
      setLoading(true)
      const values = await form.validateFields()
      // Kiểm tra nếu đang chỉnh sửa thì sử dụng updatePractiseByID, ngược lại sử dụng createPractise
      const serviceFunction = edit
        ? PractiseService.updatePractiseByID
        : PractiseService.createPractise
      // Tạo body request
      const requestBody = {
        ...values,
        organization_name: values?.organization_name,
        practise_addr: values?.practise_addr,
        practise_name: values?.practise_name,
        practise_range: values?.practise_range,
        practise_sys_name: values?.practise_sys_name,
        tools: values?.tools,
        start_date: !!values?.Date
          ? values?.Date[0]?.format("YYYY-MM-DD HH:mm")
          : undefined,
        end_date: !!values?.Date
          ? values?.Date[1]?.format("YYYY-MM-DD HH:mm")
          : undefined,
        practise_img_url: !!avatarUpload ? avatarUpload?.url : undefined,
        practise_id: edit ? practise_id : undefined, //
        decription: !!values?.decription ? values?.decription : undefined,
        banner_start_url: null,
        banner_close_url: null,
        user_id: userInfo?.id,
      }
      const res = await serviceFunction(requestBody)
      if (res.isError) return
      getData()
      Notice({
        isSuccess: true,
        msg: edit ? "Cập nhật diễn tập thành công" : "Thêm diễn tập thành công",
      })
      if (!edit) {
        onCancel()
      }
    } catch (error) {
      Notice({
        isSuccess: false,
        msg: error.message,
      })
    } finally {
      setLoading(false)
    }
  }
  const uploadImg = async file => {
    try {
      setLoading(true)
      const formData = new FormData()
      formData.append("file_list", file)
      if (edit) {
        formData.append("practise_id", practise_id)
      }
      const res = await FileService.uploadFile(formData)
      if (res.isError) return
      setAvatarUpload(res.data[0])
    } finally {
      setLoading(false)
    }
  }
  const props = {
    onRemove(file) {
      // const listArray = avatarUpload.filter(item => item.name !== file.name)
      setAvatarUpload(undefined)
    },
  }
  const parseDateString = dateString => {
    const [time, date] = dateString.split(", ")
    const [hours, minutes] = time.split(":").map(Number)
    const [day, month, year] = date.split("/").map(Number)

    // Tạo đối tượng Date
    return new Date(year, month - 1, day, hours, minutes)
  }
  useEffect(() => {
    if (!!data) {
      form.setFieldsValue({
        ...data,
        Date: [
          !!data?.start_date
            ? dayjs(parseDateString(data?.start_date))
            : undefined,
          !!data?.end_date ? dayjs(parseDateString(data?.end_date)) : undefined,
        ],
        description: data?.description,
      })
      setAvatarUpload({
        url: !!data?.practise_img_url ? data?.practise_img_url : undefined,
      })
    }
  }, [data])
  return (
    <NewFormStyle>
      <Form form={form} layout="vertical" scrollToFirstError>
        <Row gutter={[14, 2]}>
          <Col xs={24}>
            <Row gutter={[10]} className="div-avatar">
              <Col span={16} className="wrap-avatar">
                {!!avatarUpload.url ? (
                  <img
                    style={{ height: "300px", maxWidth: "100%" }}
                    className="user-avatar"
                    src={avatarUpload?.url}
                    alt="avatar"
                  />
                ) : (
                  <div
                    className="d-flex align-items-center justify-content-center user-avatar"
                    style={{ backgroundColor: "#ddd" }}
                  >
                    <UserOutlined
                      style={{
                        fontSize: "40px",
                        color: "#fff",
                        height: "100px",
                      }}
                    />
                  </div>
                )}
              </Col>
              <Col span={8} className="boxAccount">
                <Form.Item
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                  name="Avatar"
                  rules={[
                    () => ({
                      validator(_, value) {
                        if (!!value?.find(i => i?.size > 5 * 1024 * 1024)) {
                          return Promise.reject(
                            new Error("Dung lượng file tối đa 5MB"),
                          )
                        }
                        return Promise.resolve()
                      },
                    }),
                  ]}
                >
                  <Upload
                    {...props}
                    beforeUpload={file => {
                      uploadImg(file)
                      return false
                    }}
                    accept="image/*"
                    multiple={false}
                    maxCount={1}
                    fileList={[]}
                  >
                    <div className="upload-avatar">
                      <div className="d-flex justify-content-center">
                        <Button className="sign-button-upload ">
                          <div className="sign-background-upload d-flex align-items-center">
                            <SvgIcon name="small-image-red" />
                            <div className="sign-text-upload ml-16">
                              Chọn ảnh
                            </div>
                          </div>
                        </Button>
                      </div>
                    </div>
                    <div
                      className="sign-text"
                      onClick={e => {
                        e.stopPropagation()
                      }}
                    >
                      <div>Dung lượng file tối đa 5MB</div>
                      <div>Định dạng:.JPG, .JPEG, .PNG, .SVG</div>
                    </div>
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Tên diễn tập"
              name="practise_name"
              rules={[
                {
                  required: true,
                  message: "Tên diễn tâp không được để trống",
                },
              ]}
            >
              <Input allowClear placeholder="Nhập" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Tên hệ thống diễn tập"
              name="practise_sys_name"
              rules={[
                {
                  required: true,
                  message: "hệ thông diễn tập không được để trống",
                },
              ]}
            >
              <Input allowClear placeholder="Nhập" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Đơn vị tổ chức"
              name="organization_name"
              rules={[
                {
                  required: true,
                  message: "Đơn vị tổ chức không được để trống",
                },
              ]}
            >
              <Input allowClear placeholder="Nhập" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Thời gian không được để trống",
                },
              ]}
              label="Thời gian diễn ra"
              name="Date"
            >
              <RangePicker
                showTime={{
                  format: "HH:mm",
                }}
                format="YYYY-MM-DD HH:mm"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Địa điểm diễn tập"
              name="practise_addr"
              rules={[
                {
                  required: true,
                  message: "Địa điểm diễn tập không được để trống",
                },
              ]}
            >
              <Input allowClear placeholder="Nhập" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <RegionSelect
              valueCity={form.getFieldValue("city")}
              valueDistrict={form.getFieldValue("district")}
              valueWards={form.getFieldValue("wards")}
            />
          </Col>
          <Col span={24}>
            <Form.Item label="Mô tả diễn tập" name="decription">
              <TextArea rows={4} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Button
              htmlType="submit"
              type="primary"
              onClick={() => createForm()}
            >
              Lưu
            </Button>
          </Col>
        </Row>
      </Form>
    </NewFormStyle>
  )
}

export default FormInfo
