import { Form, Modal, Select, Space, Spin } from "antd"
import React, { useEffect, useState } from "react"
import { AdminStyled } from "src/components/Layouts/component/LayoutAdmin/styled"
import Button from "src/components/MyButton/Button"
import Notice from "src/components/Notice"
import STORAGE, { getStorage } from "src/lib/storage"
import PractiseService from "src/services/PractiseService"
import Commit from "./Components/Modals/DetailRe/Commit"

const RegisterR = ({ open, onOk, onCancel, getData }) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [listRole, setListRole] = useState()
  const [roleType, setRoleType] = useState(false)
  const [isModalCommit, setIsModalCommit] = useState(false)

  const userInfo = getStorage(STORAGE.USER_INFO)

  const getListRole = async () => {
    try {
      setLoading(true)
      const res = await PractiseService.getRoleInPractise({
        isRegister: true,
      })
      if (res.isOk) {
        let ls = res.data.map((item, index) => {
          return {
            value: item?.id,
            label: item?.description,
            commit: item?.commit,
          }
        })
        setListRole(ls)
      }
    } finally {
      setLoading(false)
    }
  }
  const HandleSubmit = async () => {
    try {
      setLoading(true)
      const values = await form.validateFields()
      const requestBody = {
        ...values,
        practise_id: open,
        user_id: userInfo.id,
      }
      const res = await PractiseService.registerToPractise(requestBody)
      if (res && res.isOk) {
        Notice({ msg: res.message, isSuccess: true })
        onCancel()
        getData()
      }
    } catch (error) {
      Notice({ msg: error.message, isSuccess: false })
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getListRole()
  }, [])

  return (
    <AdminStyled>
      <Modal
        title={"Đăng ký tham gia diễn tập"}
        open={open}
        onOk={onOk}
        onCancel={onCancel}
        width={600}
        footer={
          <Space>
            <Button
              btnType="primary"
              className="btn-hover-shadow"
              type="submit"
              onClick={() => {
                HandleSubmit()
              }}
              loading={loading}
              htmlType="submit"
            >
              Xác nhận
            </Button>
          </Space>
        }
      >
        <Spin spinning={loading}>
          <Form form={form} layout="vertical" scrollToFirstError>
            <Form.Item
              label="Ban muốn đăng ký tham gia diễn tập với vai trò"
              name="team_type"
              rules={[
                {
                  required: true,
                  message: "Không được để trống",
                },
              ]}
            >
              <Select
                defaultValue="Chọn"
                onChange={(event, item) => {
                  setRoleType(item?.commit)
                }}
                allowClear
                options={listRole}
              />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
      {!!isModalCommit && (
        <Commit
          open={isModalCommit}
          onOk={() => setIsModalCommit(false)}
          onCancel={() => setIsModalCommit(false)}
          onCancelCommitOne={onCancel}
        />
      )}
    </AdminStyled>
  )
}

export default RegisterR
