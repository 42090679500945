import { Col, Form, Input, Modal, Radio, Row, Select, Space, Spin } from "antd"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { AdminStyled } from "src/components/Layouts/component/LayoutAdmin/styled"
import Button from "src/components/MyButton/Button"
import Notice from "src/components/Notice"
import STORAGE, { getStorage } from "src/lib/storage"
import AuthService from "src/services/AuthService"
import PractiseService from "src/services/PractiseService"
import RegionService from "src/services/RegionService"
import UserService from "src/services/UserService"

const NewUserModal = ({ open, onOk, onCancel, getData, listPractise }) => {
  const [valueCheck, setValueCheck] = useState(0)
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const { id } = useParams()
  const isInfo = getStorage(STORAGE.USER_INFO)
  const [listPosition, setListPosition] = useState()
  const [listTeam, setListTeam] = useState()
  const [listUser, setListUser] = useState()
  const [listRole, setListRole] = useState()
  const [listUserInviter, setListUserInviter] = useState()
  const [teamName, setTeamName] = useState()
  const [roleName, setRoleName] = useState()
  const [position, setPosition] = useState()
  const onChange = e => {
    setValueCheck(e.target.value)
  }
  const createForm = async () => {
    try {
      setLoading(true)
      const values = await form.validateFields()
      const res = !open.isdata
        ? await PractiseService.addUserInPractise({
            ...values,
            options: `${valueCheck}`,
            practise_id: id,
            user_id: valueCheck === 1 ? undefined : values.user_id,
            permission: `${values?.permission}`,
            // region_level_1_id: 1,
            // region_level_2_id: 2,
            orgnization_id: "1",
            position_name: !!position ? position : undefined,
            teamName: !!teamName ? teamName : undefined,
            role_group_name: !!roleName ? roleName : undefined,
          })
        : await PractiseService.moveUsertoTeam({
            ...values,
            user_id: open?.data?.User?.id,
            practise_id: id,
          })
      if (res.isError) return
      Notice({
        isSuccess: true,
        msg: "Thêm người tham gia diễn tập thành công",
      })
      getData()
      onCancel()
      // setOpenVerifyModal()
      // handleCancel()
    } finally {
      setLoading(false)
    }
  }
  const getPosition = async () => {
    try {
      setLoading(true)
      const resp = await PractiseService.getPositionInPractise()
      if (resp.isOk) {
        let ls = resp.data.map((item, index) => {
          return {
            value: item?.id,
            label: item?.value,
          }
        })
        setListPosition(ls)
      }
    } finally {
      setLoading(false)
    }
  }
  const [checkInputSelect, setCheckInputSelect] = useState(false)
  const getTeam = async event => {
    try {
      setLoading(true)
      const resp = await PractiseService.getTeamInPractise({
        practise_id: id,
        team_type: event,
      })
      if (resp.isOk) {
        let ls = resp.data.map((item, index) => {
          return {
            value: item?.id,
            label: item?.team_name,
          }
        })
        setListTeam(ls)
      }
    } finally {
      setLoading(false)
    }
  }
  const getUser = async () => {
    try {
      setLoading(true)
      const resp = await UserService.getAllAccountUser({
        account_type: 2,
      })
      if (resp.isOk) {
        let ls = resp.data.map((item, index) => {
          return {
            value: item?.id,
            label: item?.organization_name,
          }
        })
        setListUser(ls)
      }
    } finally {
      setLoading(false)
    }
  }
  const getRole = async () => {
    try {
      setLoading(true)
      const resp = await AuthService.getPermissionGroup({
        account_type: 2,
      })
      if (resp.isOk) {
        let ls = resp.data.map((item, index) => {
          return {
            value: item?.id,
            label: item?.description,
          }
        })
        setListRole(ls)
      }
    } finally {
      setLoading(false)
    }
  }
  const getUserInviter = async searchText => {
    try {
      setLoading(true)
      const resp = await PractiseService.getUserForPractise({
        select: !!searchText ? searchText : undefined,
      })
      if (resp.isOk) {
        let ls = resp.data.map((item, index) => {
          return {
            value: item?.id,
            label: item?.fullname,
          }
        })
        setListUserInviter(ls)
      }
    } finally {
      setLoading(false)
    }
  }
  const [listProvince, setListProvice] = useState()
  const [listDistrict, setListDistrict] = useState()
  const [listWards, setListWards] = useState()

  // tỉnh thành
  const getProvince = async () => {
    try {
      setLoading(true)
      const resp = await RegionService.getListProvince()
      if (resp.isOk) {
        let ls = resp.data.map((item, index) => {
          return {
            value: item?.id,
            label: item?.region_name,
          }
        })
        setListProvice(ls)
      }
    } finally {
      setLoading(false)
    }
  }
  // Quận huyện
  const getDistrict = async event => {
    try {
      setLoading(true)
      const resp = await RegionService.getListDistrict({
        province_id: event,
      })
      if (resp.isOk) {
        let ls = resp.data.map((item, index) => {
          return {
            value: item?.id,
            label: item?.region_name,
          }
        })
        setListDistrict(ls)
      }
    } finally {
      setLoading(false)
    }
  }
  // Xã phường
  const getWards = async event => {
    try {
      setLoading(true)
      const resp = await RegionService.getListwards({
        district_id: event,
      })
      if (resp.isOk) {
        let ls = resp.data.map((item, index) => {
          return {
            value: item?.id,
            label: item?.region_name,
          }
        })
        setListWards(ls)
      }
    } finally {
      setLoading(false)
    }
  }
  //
  useEffect(() => {
    getUserInviter()
    getProvince()
    getPosition()
    getUser()
    getRole()
  }, [])
  useEffect(() => {}, [listUserInviter])
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
  return (
    <AdminStyled>
      <Modal
        title={
          open?.isdata ? "Chuyển đổi diễn tập" : "Thêm người tham gia diễn tập"
        }
        open={open}
        onOk={onOk}
        onCancel={onCancel}
        width={1000}
        footer={
          <Space>
            <Button
              btnType="primary"
              className="btn-hover-shadow"
              type="submit"
              htmlType="submit"
              onClick={() => createForm()}
            >
              Ghi lại
            </Button>
          </Space>
        }
      >
        <Form
          form={form}
          layout="vertical"
          className="mt-20"
          scrollToFirstError
        >
          <Row gutter={[14, 10]}>
            {!open?.isdata && (
              <Col span={24} className="d-flex justify-content-center">
                <Radio.Group onChange={onChange} value={valueCheck}>
                  <Radio value={0}>
                    Mời (Người đã có tài khoản trên hệ thống)
                  </Radio>
                  {/* <Radio value={1}>
                    Người mới (chưa có tài khoản trên hệ thống)
                  </Radio> */}
                </Radio.Group>
              </Col>
            )}
            {valueCheck !== 0 && (
              <Col span={24} style={{ color: "red" }}>
                Lưu ý: Người mới sẽ được tạo tài khoản mới trên hệ thống, thông
                tin đăng nhập sẽ được gửi về mail của người được đăng ký. Vui
                lòng kiểm tra mail!
              </Col>
            )}
            <Col span={24}>
              <Form.Item
                label="Đội diễn tập"
                name="role_group_id"
                rules={[
                  {
                    required: true,
                    message: "Đội diễn tập không được để trống",
                  },
                ]}
              >
                <Select
                  allowClear
                  onChange={(event, record) => {
                    getTeam(event)
                    setCheckInputSelect(record?.type)
                    setRoleName(record?.label)
                  }}
                  options={listPractise}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Tên Đội diễn tập"
                name="practise_group_id"
                rules={[
                  {
                    required: checkInputSelect,
                    message: "Tên Đội diễn tập không được để trống",
                  },
                ]}
              >
                <Select
                  disabled={!checkInputSelect}
                  allowClear
                  options={listTeam}
                  onChange={(event, record) => {
                    setTeamName(record?.label)
                  }}
                />
              </Form.Item>
            </Col>
            {valueCheck !== 0 && (
              <Col span={24}>
                <Form.Item
                  label="Họ và tên"
                  name="fullname"
                  rules={[
                    {
                      required: true,
                      message: "Họ và tên không được để trống",
                    },
                  ]}
                >
                  <Input allowClear placeholder="Nhập" />
                </Form.Item>
              </Col>
            )}
            {!open?.isdata && (
              <Col span={24}>
                <Form.Item label="Cơ quan" name="orgnization_id">
                  <Select
                    showSearch
                    allowClear
                    filterOption={filterOption}
                    options={listUser}
                  />
                </Form.Item>
              </Col>
            )}
            {/* thiêu input tên cơ quan */}
            {valueCheck !== 0 ? (
              <Col span={24}>
                <Form.Item
                  label="Tên cơ quan"
                  name="work_unit"
                  rules={[
                    {
                      required: true,
                      message: "Người được mời không được để trống",
                    },
                  ]}
                >
                  <Input allowClear placeholder="Nhập" />
                </Form.Item>
              </Col>
            ) : (
              <>
                {!open?.isdata && (
                  <Col span={24}>
                    <Form.Item
                      label="Người được mời"
                      name="user_id"
                      rules={[
                        {
                          required: true,
                          message: "Người được mời không được để trống",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        // onChange={onChange}
                        // onSearch={onSearch}
                        filterOption={filterOption}
                        options={listUserInviter}
                      />
                    </Form.Item>
                  </Col>
                )}
              </>
            )}
            {!open?.isdata && (
              <Col span={24}>
                <Form.Item
                  label="Quyền"
                  name="permission"
                  // rules={[
                  //     {
                  //         required: true,
                  //         message: 'Quyền không được để trống',
                  //     },
                  // ]}
                >
                  <Select allowClear options={listRole} />
                </Form.Item>
              </Col>
            )}
            <Col span={12}>
              <Form.Item
                label="Vai trò trong diễn tập"
                name="position_id"
                rules={[
                  {
                    required: true,
                    message: "Vai trò trong diễn tập không được để trống",
                  },
                ]}
              >
                <Select
                  onChange={(event, record) => {
                    setPosition(record?.label)
                  }}
                  allowClear
                  options={listPosition}
                />
              </Form.Item>
            </Col>
            {!open?.isdata && (
              <Col span={12}>
                <Form.Item label="Chức vụ trong diễn tập " name="job_title">
                  <Input />
                </Form.Item>
              </Col>
            )}
            {valueCheck !== 0 && (
              <Col span={24}>
                <Row gutter={[14, 2]}>
                  <Col span={8}>
                    <Form.Item label="Điện thoại" name="phone_number">
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Email không được để trống",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label="Tỉnh/Thành phố"
                      name="city"
                      rules={[
                        {
                          required: true,
                          message: "Email không được để trống",
                        },
                      ]}
                    >
                      <Select
                        allowClear
                        options={listProvince}
                        onChange={event => {
                          getDistrict(event)
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label="Quận/Huyện"
                      name="district"
                      rules={[
                        {
                          required: true,
                          message: "Email không được để trống",
                        },
                      ]}
                    >
                      <Select
                        allowClear
                        options={listDistrict}
                        onChange={event => {
                          getWards(event)
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={16}>
                    <Form.Item
                      label="Xã/Phường"
                      name="wards"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Quyền không được để trống",
                      //   },
                      // ]}
                    >
                      <Select allowClear options={listWards} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </Form>
      </Modal>
    </AdminStyled>
  )
}

export default NewUserModal
