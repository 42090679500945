import { Col, Image, Row, Space } from "antd"
import React, { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { AdminStyled } from "src/components/Layouts/component/LayoutAdmin/styled"
import Button from "src/components/MyButton/Button"
import TableCustom from "src/components/Table/CustomTable"
import PractiseService from "src/services/PractiseService"
import ModalAdd from "./Modals/Documents/ModalAdd"
import { FloatActionWrapper } from "src/components/FloatAction/styles"
import ButtonCircle from "src/components/MyButton/ButtonCircle"
import ModalDetails from "./Modals/Documents/ModalDetails"
import JSZip from "jszip"
import { saveAs } from "file-saver"
import CB1 from "src/components/Modal/CB1"
import Notice from "src/components/Notice"

const Document = ({ detail }) => {
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({
    page_size: 10,
    page_number: 1,
  })
  const { id } = useParams()
  const [total, setTotal] = useState(0)
  const [listData, setListData] = useState()
  const [isModalAdd, setIsModalAdd] = useState(false)
  const [isModalDetails, setIsModalDetails] = useState(false)
  const [button, setButtonData] = useState()

  const getData = async () => {
    try {
      setLoading(true)
      const resp = await PractiseService.getAllFilesInPractise({
        ...pagination,
        practise_id: id,
      })
      if (resp.isOk) {
        setListData(resp?.data.reslut)
        setTotal(resp?.data?.total)
        setButtonData(resp?.button)
      }
    } finally {
      setLoading(false)
    }
  }
  const handleDownload = async record => {
    try {
      setLoading(true)
      // Tải file từ URL

      // Tạo đối tượng JSZip
      const zip = new JSZip()
      // Thêm file vào ZIP
      zip.file(record?.title, record?.url)

      // Tạo file ZIP và tải xuống
      const zipBlob = await zip.generateAsync({ type: "blob" })
      saveAs(zipBlob, "Tailieu.zip")
    } catch (error) {
      console.error("Error downloading or zipping file:", error)
    } finally {
      setLoading(false)
    }
  }
  const columns = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      width: 50,
      render: (val, record, idx) => (
        <div className="text-center">
          {idx + 1 + pagination.page_size * (pagination.page_number - 1)}
        </div>
      ),
      align: "center",
    },
    {
      title: "Tên tài liệu",
      dataIndex: "title",
      key: "title",
      width: 600,
      render: (val, record, idx) => (
        <div
          className="text-center"
          onClick={() => {
            return (
              <Image
                width={200}
                src="https://gw.alipayobjects.com/zos/antfincdn/LlvErxo8H9/photo-1503185912284-5271ff81b9a8.webp"
              />
            )
          }}
        >
          {record.File.title}
        </div>
      ),
    },
    {
      title: "Người gửi",
      dataIndex: "name",
      key: "name",
      width: 150,
      render: (val, record, idx) => (
        <div className="text-center">
          {record?.User?.fullname || record?.Organization?.tag}
        </div>
      ),
    },
    {
      title: "Thao tác",
      dataIndex: "operation",
      key: "operation",
      align: "center",
      render: (val, record) => (
        <>
          <div>{record?.User?.work_unit}</div>
          {button?.btn_AddDoc && (
            <FloatActionWrapper size="small" className="float-action__wrapper">
              <Space>
                <ButtonCircle
                  title="Tải file"
                  iconName="export-kq"
                  onClick={() => {
                    handleDownload(record?.File)
                  }}
                />
                <ButtonCircle
                  title="Xoá"
                  iconName="delete-outline"
                  onClick={() =>
                    CB1({
                      title: `Bạn có chắc chắn muốn xóa "${record?.File.title}" ?`,
                      icon: "trashRed",
                      okText: "Đồng ý",
                      onOk: () => {
                        setLoading(true)
                        PractiseService.deleteFileByID({
                          practise_id: id,
                          file_id: record?.File?.id,
                        })
                          .then(res => {
                            if (res?.isOk) {
                              getData()
                              Notice({
                                msg: "Xóa thành công!",
                              })
                            }
                          })
                          .finally(() => setLoading(false))
                      },
                    })
                  }
                />
              </Space>
            </FloatActionWrapper>
          )}
        </>
      ),
    },
  ]
  useEffect(() => {
    getData()
  }, [pagination])

  return (
    <AdminStyled>
      <Row gutter={[10, 15]} className="mr-20">
        <Col span={24}>
          <Row className="justify-content-space-between">
            <Col className="d-flex" style={{ alignItems: "center" }}>
              <div className="Text-title">Danh sách tài liệu: ({total})</div>
            </Col>
            <Col>
              {button?.btn_AddDoc && (
                <Space>
                  <Button btnType="third" onClick={() => setIsModalAdd(true)}>
                    Thêm tài liệu
                  </Button>
                </Space>
              )}
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <TableCustom
            loading={loading}
            isPrimary
            columns={columns}
            dataSource={listData}
            onRow={(record, rowIndex) => {
              return {
                onClick: event => {
                  setIsModalDetails(record)
                }, // click row
              }
            }}
            sticky={{ offsetHeader: -14 }}
            scroll={{ x: "800px" }}
            style={{ marginBottom: 4 }}
            pagination={{
              hideOnSinglePage: total <= 10,
              current: pagination.page_number,
              PageSize: pagination.page_size,
              responsive: true,
              total,
              locale: { items_per_page: "" },
              showSizeChanger: total > 10,
              onChange: (page, page_size) => {
                setPagination({
                  ...pagination,
                  page_number: page,
                  page_size: page_size,
                })
              },
            }}
            showPagination
            footerLeft={<div className="d-flex mt-20" />}
            widthScroll={1200}
          />
        </Col>
      </Row>
      {!!isModalAdd && (
        <ModalAdd
          open={isModalAdd}
          onOk={() => setIsModalAdd(false)}
          onCancel={() => setIsModalAdd(false)}
          getData={getData}
        />
      )}
      {!!isModalDetails && (
        <ModalDetails
          open={isModalDetails}
          onOk={() => setIsModalDetails(false)}
          onCancel={() => setIsModalDetails(false)}
          getData={getData}
        />
      )}
    </AdminStyled>
  )
}

export default Document
