import { Col, Tabs, Row, Space } from "antd"
import React, { useEffect, useState } from "react"
import { DetailReStyle } from "../styled"
import UserJoin from "./Components/UserJoin"
import Document from "./Components/Document"
import ApproveRegistration from "./Components/ApproveRegistration"
import Button from "src/components/MyButton/Button"
import FileBM01 from "./Components/FileBM01"
import FileBM02 from "./Components/FileBM02"
import Plan from "./Components/Plan"
import TabOpening from "./Components/TabOpening"
import ListReport from "./Components/ListReport"
import Evaluate from "./Components/Evaluate"
import SvgIcon from "src/components/SvgIcon"
import FormInfo from "./Components/FormInfo"
import { useParams } from "react-router-dom"
import OperationsManagement from "./Components/OperationsManagement"
import { DoubleLeftOutlined } from "@ant-design/icons"

const DetailRe = ({ open, onOk, onCancel, data, getData }) => {
  const [current, setCurrent] = useState(0)
  const [collapsed, setCollapsed] = useState(false)
  const { id } = useParams()

  useEffect(() => {
    // Uncomment the next line to enable fetching PDF data on component mount
    // getDataPdf();
  }, [collapsed])

  const onChange = value => {
    setCurrent(value)
  }

  const steps = [
    {
      key: "1",
      label: (
        <div className="d-flex">
          <SvgIcon className="mr-4" name="quytrinh-tt" />
          {!collapsed ? "Thông tin chung" : ""}
        </div>
      ),
      children: (
        <FormInfo
          onCancel={onCancel}
          edit={true}
          practise_id={id}
          getData={getData}
          data={data}
        />
      ),
    },
    {
      key: "2",
      label: (
        <div className="d-flex">
          <SvgIcon className="mr-4" name="quytrinh-pa" />
          {!collapsed ? "Phương án triển khai" : ""}
        </div>
      ),
      children: <Plan />,
    },
    {
      key: "3",
      label: (
        <div className="d-flex">
          <SvgIcon className="mr-4" name="quytrinh-pp" />
          {!collapsed ? "Thành Lập ban tổ chức" : ""}
        </div>
      ),
      children: <UserJoin data={data} organizers={true} />,
    },
    {
      key: "4",
      label: (
        <div className="d-flex">
          <SvgIcon className="mr-4" name="quytrinh-ddk" />
          {!collapsed ? "Duyệt đăng ký tham gia" : ""}
        </div>
      ),
      children: <ApproveRegistration />,
    },
    {
      key: "5",
      label: (
        <div className="d-flex">
          <SvgIcon className="mr-4" name="quytrinh-ddtg" />
          {!collapsed ? "Danh sách tham gia" : ""}
        </div>
      ),
      children: <UserJoin data={data} />,
    },
    {
      key: "6",
      label: (
        <div className="d-flex">
          <SvgIcon className="mr-4" name="quytrinh-nq" />
          {!collapsed ? "Xây dựng nội quy diễn tập" : ""}
        </div>
      ),
      children: <FileBM01 next={() => setCurrent(current + 1)} />,
    },
    {
      key: "7",
      label: (
        <div className="d-flex">
          <SvgIcon className="mr-4" name="quytrinh-km" />
          {!collapsed ? "Khai mạc diễn tập" : ""}
        </div>
      ),
      children: (
        <TabOpening
          data={data}
          Opening={true}
          next={() => setCurrent(current + 1)}
        />
      ),
    },
    {
      key: "8",
      label: (
        <div className="d-flex">
          <SvgIcon className="mr-4" name="quytrinh-tl" />
          {!collapsed ? "Tài liệu hỗ trợ" : ""}
        </div>
      ),
      children: <Document />,
    },
    {
      key: "9",
      label: (
        <div className="d-flex">
          <SvgIcon className="mr-4" name="quytrinh-1lhd" />
          {!collapsed ? "Quản ký hoạt động" : ""}
        </div>
      ),
      children: <OperationsManagement />,
    },
    {
      key: "10",
      label: (
        <div className="d-flex">
          <SvgIcon className="mr-4" name="quytrinh-thdt" />
          {!collapsed ? "Thực hiện diễn tập" : ""}
        </div>
      ),
      children: <ListReport />,
    },
    {
      key: "11",
      label: (
        <div className="d-flex">
          <SvgIcon className="mr-4" name="quytrinh-dg" />
          {!collapsed ? "Đánh giá diễn tập" : ""}
        </div>
      ),
      children: <Evaluate />,
    },
    {
      key: "12",
      label: (
        <div className="d-flex">
          <SvgIcon className="mr-4" name="quytrinh-bm" />
          {!collapsed ? "Bế mạc diễn tập" : ""}
        </div>
      ),
      children: <TabOpening Opening={false} />,
    },
    {
      key: "13",
      label: (
        <div className="d-flex">
          <SvgIcon className="mr-4" name="quytrinh-tk" />
          {!collapsed ? "Báo cáo tổng kết" : ""}
        </div>
      ),
      children: <FileBM02 next={() => setCurrent(current + 1)} />,
    },
  ]

  return (
    <div>
      <DetailReStyle>
        <Row>
          <Col span={24}>
            <Tabs
              // style={{ height: "100%" }}
              defaultActiveKey="1"
              onChange={onChange}
              tabPosition="left"
              items={steps}
            />
            <div>
              <span className="collapsed-item">
                <div
                  className="collapsed-icon"
                  onClick={() => setCollapsed(!collapsed)}
                >
                  <Space>
                    <DoubleLeftOutlined rotate={collapsed ? 180 : 0} />
                    {collapsed ? "Mở rộng" : "Thu gọn"}
                  </Space>
                </div>
              </span>
            </div>
          </Col>
        </Row>
      </DetailReStyle>
    </div>
  )
}

export default DetailRe
